import { useCallback, useEffect, useState } from "react";
import { ERRORS } from "../helpers/errors";
import { log } from "../helpers";
import { trackGa } from "../tracking/googleAnalytics";
import track from "../tracking/analytics";

const useParamsForCustomizable = () => {
  const [paramsLink, setParamsLink] = useState("");
  const [addProps, setAddProps] = useState({});
  const [isRendered, setIsRendered] = useState(false);

  const updateProps = useCallback((args) => {
    setAddProps((prevState) => ({ ...prevState, ...args }));
  }, []);
  const sendEvent = ({ category, action, label = "", software }) => {
    trackGa(action, {
      category,
      label,
      transport_type: "beacon",
    });
    track(category, action, label, null, software);
  };

  useEffect(() => {
    let isEventError = false;
    try {
      const urlParams = new URL(window.location.href).searchParams;
      const { url, ci, software, addon, ec, ea, el } =
        Object.fromEntries(urlParams);
      const addParams = {
        ...(url && { url }),
        ...(ci && { ci }),
        ...(addon === "true" && { advancedBoosterPackDefault: true }),
      };
      if (software) {
        updateProps({ software });
        addParams.software = software;
      }

      const params = new URLSearchParams(addParams).toString();
      params && setParamsLink(`&${params}`);

      if (ec && ea && software) {
        isEventError = true;
        sendEvent({ category: ec, action: ea, label: el, software });
      }
    } catch (error) {
      const logTitle = isEventError
        ? ERRORS.ANALYTICS_ERRORS.ERROR_SENDING_EVENT_TO_ANALYTICS
        : ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS;
      log(error, logTitle, true);
    } finally {
      setIsRendered(true);
    }
  }, [updateProps]);

  return { addProps, isRendered, paramsLink };
};

export default useParamsForCustomizable;
